import { insertToObject } from '../insertToObject';
/**
 *
 * @param {object} obj object, where we want update value
 * @param {string} key path to value, separated by dots
 * @param {*} value value, which we want to set
 * @returns {object} exist object with new value
 */
export function setObjectValue(obj, key, value) {
  const path = key.split('.');
  return insertToObject(obj, path, value);
}
