import React, { Fragment, } from 'react';
import { CommonList, } from 'Components';
import { TeamForm } from './form';


export class Team extends CommonList {
  get tableConfig() {
    return [
      {
        title: 'Role',
        accessor: 'role',
      },
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Nickname',
        accessor: 'nickname',
      },
      ...(this.props.shirts ? [
        {
          title: 'Shirt Style',
          accessor: 'shirt_style',
        },
        {
          title: 'Shirt Size',
          accessor: 'shirt_size',
        },
      ] : []),
      this.actionsColumn,
    ]
  }
  get addons() {
    return (
      <Fragment>
        {!this.props.team.name && (
          <div className="complete-mark mb-20">
            <div className="complete-mark__block complete-mark__block--warning">
              <span className="complete-mark__text">Team Name required</span>
            </div>
          </div>
        )}
        {!this.props.team.preferred_start && (
          <div className="complete-mark mb-20">
            <div className="complete-mark__block complete-mark__block--warning">
              <span className="complete-mark__text">Preferred Start required</span>
            </div>
          </div>
        )}
        <TeamForm {...this.props} />
      </Fragment>
    );
  }
}
