import React, { Fragment } from 'react';
import { components } from 'Common';
import { notifyOnFailedResponse, notifyOnSuccesResponse } from 'Helpers/notifications';
import { NotificationManager } from 'react-notifications';
const { Button } = components;


export class CommonForm extends components.CommonForm {

  additionParams = {
    notifyOnFailedResponse,
    notifyOnSuccesResponse,
  }

  static defaultProps = {
    onFormInvalid: () => {
      NotificationManager.warning(
        'You have validation errors in form. Check validation messages.',
        'Form can\'t be submitted'
      );
    },
  }

  get body() {
    return null;
  }

  get formFooter() {
    const { id } = this.state.item;
    const { item_name } = this.props;

    return (
      <div className="button-wrapper">
        <Button type="submit" className="button-wrapper__button--auto-width">
          {id ? 'Update' : 'Create'} {item_name}
        </Button>
      </div>
    )
  }

  render() {
    const { id } = this.state.item;
    const { item_name, url } = this.props;
    return (
      <Fragment>
        <h1 className="system__title system__title--with-button">
          {id ? 'Edit' : 'New'} {item_name}
          <Button type="link" href={url}
            className="system__title-link button-wrapper__button--auto-width">
            Cancel
          </Button>
        </h1>
        <form onSubmit={this.handleSubmit}>
          {this.body}
        </form>
      </Fragment>
    )
  }
}
