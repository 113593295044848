import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from './Button.jsx';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';

export const IconButton = ({ icon, className, ...buttonProps }) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'iconButton');
  const btnClassName = classnames(context.container, className);
  return (
    <Button {...buttonProps} className={btnClassName}>
      <i className={icon}></i>
    </Button>
  );
}

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}
