import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Datetime from 'react-datetime';
import { toMomentDate } from 'Helpers/DateTime';
import {
  DISPLAY_DATE_FORMAT, TIME_FORMAT,
} from 'Constants/dateTime';
import { PickerWrapper } from './Wrapper';
import { DATE_TIME_FORMAT } from 'Constants/dateTime';

export const DateTimePicker = ({ value, onChange, disabled, ...props }) => {
  const classNames = classnames('input-group__date-wrapper', props.className);
  const isInvalid = classNames.indexOf('is-invalid') > -1;

  function handleChange(value) {
    onChange(value.format ? value.format(DATE_TIME_FORMAT) : value);
  }

  return (
    <PickerWrapper isInvalid={isInvalid} disabled={disabled}>
      <Datetime  {...props} onChange={handleChange} value={toMomentDate(value)}
        className={classNames} inputProps={{ disabled }} inputProps={{ className: 'input-group__input' }} />
    </PickerWrapper>
  )
};

DateTimePicker.propTypes = {
  /* DateTimePicker addition classes */
  className: PropTypes.string,
};

DateTimePicker.defaultProps = {
  dateFormat: DISPLAY_DATE_FORMAT,
  timeFormat: TIME_FORMAT,
}
