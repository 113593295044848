import React, { Fragment } from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
const { FormGroup } = components;
export class TournamentMembershipForm extends CommonForm {
  modelName = 'tournament_membership';

  defaultItem = {
    name: '',
    price: '',
    quantity: '',
    tournament_id: '',
    description: '',
    package_group_id: '',
    shirts: false,
  }

  handleChangeName = ({ target }) => this.changeValue('name', target.value);
  handleChangePrice = ({ target }) => this.changeValue('price', target.value);
  handleChangeTournament = ({ target }) => this.changeValue('tournament_id', target.value);
  handleChangeQuantity = ({ target }) => this.changeValue('quantity', target.value);
  handleChangeDescription = ({ target }) => this.changeValue('description', target.value);
  handleChangePackageGroup = ({ target }) => this.changeValue('package_group_id', target.value);
  handleChangeShirtsActive = ({ target }) => this.changeValue('shirts', target.checked);

  get body() {
    const { item } = this.state;
    return (
      <div className="card card--size-md">
        <FormGroup label="Name" isRequired
          validationMessages={this.validationMessages.name}>
          <input type="text" className="input-group__input" value={item.name}
            onChange={this.handleChangeName} />
        </FormGroup>
        <FormGroup label="Sponsorship Package">
          <input value={item.price} className="input-group__input" type="number"
            onChange={this.handleChangePrice} />
        </FormGroup>
        <FormGroup label="Quantity">
          <input value={item.quantity} className="input-group__input" type="number"
            onChange={this.handleChangeQuantity} />
        </FormGroup>
        <FormGroup label="Description">
          <textarea value={item.description} className="input-group__input"
            onChange={this.handleChangeDescription}>
          </textarea>
        </FormGroup>
        <FormGroup label="Tournament">
          <select className="input-group__input" value={item.tournament_id}
            onChange={this.handleChangeTournament}>
            <option hidden></option>
            {this.props.tournaments.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </FormGroup>
        <FormGroup label="Package Groups">
          <select className="input-group__input" value={item.package_group_id}
            onChange={this.handleChangePackageGroup}>
            <option hidden></option>
            {this.props.package_groups.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </FormGroup>
        <FormGroup label="Shirts">
        <div className="checkbox-wrapper flex-actions__line-item" >
            <div className="checkbox-wrapper__check-style">
              <input type="checkbox"
                checked={!!item.shirts}
                value={item.shirts}
                onChange={this.handleChangeShirtsActive}
                className="checkbox-wrapper__checkbox" 
                id='shirts'
              />
              <label htmlFor='shirts'></label>
              </div>
            </div>
        </FormGroup>
        {this.formFooter}
      </div>
    );
  }
}
