import React from 'react';
import { CommonList } from 'Components';
export class PackageGroups extends CommonList {

  get tableConfig() {
    return [
      {
        title: 'Package Group Name',
        accessor: 'name',
        sortable: true,
      },
      {
        title: 'Color',
        accessor: 'color',
        sortable: false,
        cellFn: ({ item }) => (
          <div style={{ backgroundColor: item.color, height: 20 }}></div>
        )
      },
      this.actionsColumn,
    ];
  }
}
