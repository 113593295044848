import React, { forwardRef, useContext, } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';

export const Select = forwardRef(({ className, invalid, size, ...selectProps }, ref) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'input');
  const selectClassName = classnames(className, context[size], {
    [context[invalid]]: invalid,
  });
  return (
    <select {...selectProps} className={selectClassName} />
  );
});

Select.propTypes = {
  invalid: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large',]),
};

Select.defaultProps = {
  size: 'medium',
};
