import React, { Fragment } from 'react';
import Paginator from 'Components/paginator';
import Preloader from 'Components/Preloader';
import { notifyOnFailedResponse, notifyOnSuccesResponse } from 'Helpers/notifications';
import { components, helpers } from 'Common';
const { Button, Table, IconButton } = components;
const { AJAX } = helpers;
const tableClasses = {
  tableClassName: 'table',
  theadClassName: 'table__head',
  tbodyClassName: 'table__body',
  thClassName: 'table__th',
  tdClassName: 'table__td',
  trClassName: 'table__tr',
}
export class CommonList extends components.CommonList {

  state = {
    ...this.state,
    filter: {},
  }

  additionParams = {
    notifyOnFailedResponse,
    notifyOnSuccesResponse
  }

  static defaultProps = {
    fetchMethod: 'get',
    defaultSortColumn: 'name',
    defaultPerPage: 10,
    hasPagination: true,
  };

  handleRemoveItem = ({ id }) => {
    if (!!confirm('Are you sure?')) {

      const url = `${this.destroyUrl}/${id}`;
      const request = AJAX.delete(url, this.additionParams);

      request.then(() => {
        this.setState({ itemToRemove: null });
        this.fetchData();
      });
      return request;
    }
  }

  handleChangeFilter = (name, value) => {
    this.setState({
      filter: { ...this.state.filter, [name]: value, },
      pagination: { ...this.state.pagination, page: 1 },
    }, () => {
      this.fetchData({ filter: this.state.filter })
    });
  }

  get actionsColumn() {
    return {
      title: 'Actions',
      accessor: 'actions',
      style: {
        width: '100px',
        textAlign: 'right',
      },
      cellFn: ({ item }) => {
        const handleDeleteClick = () => {
          this.handleRemoveItem(item);
        }

        return (
          <div className="table__actions icon-buttons">
            <IconButton type="link" className="icon-buttons__button"
              href={`${this.props.url}/${item.id}/edit`} icon='fas fa-edit'
            />
            <IconButton className="icon-buttons__button" icon="fas fa-trash"
              onClick={handleDeleteClick} />
          </div>
        )
      }
    };
  }
  get table() {
    const { items, sorting, pagination, loading } = this.state;

    if (!!loading) {
      return (<Preloader />);
    }

    return (
      <Table data={items} config={this.tableConfig} sorting={sorting} pagination={pagination}
        onSortChange={this.handleChangeSort} {...tableClasses}
      />
    );
  }

  get pagination() {
    const { items, pagination, loading } = this.state;

    if (!items.length || loading) {
      return null;
    }
    return (
      <Paginator showPaginationInfo={false} {...pagination}
        onChange={this.handleChangePagination} maxPages={5} showSideButtons />
    )
  }

  render() {
    const { name, name_singular } = this.props;

    return (
      <Fragment>
        <h1 className="system__title system__title--with-button">
          {name}
          {!!name_singular && (
            <Button type="link" href={`${this.props.url}/new`}
              className="system__title-link button-wrapper__button--auto-width">
              <i className="fas fa-plus"></i>
              New {name_singular}
            </Button>
          )}
        </h1>

        <div className="card">
          {this.addons}
          <div className="table-wrapper">
            {this.table}
          </div>
          {this.pagination}
        </div>

      </Fragment>
    );
  }
}
