export function getQueryParams(str = location.search) {
  const queryStr = str[0] === '?' ? str.substr(1) : str;
  return queryStr
    .split('&')
    .reduce((res, paramStr) => {
      const [key, val] = paramStr.split('=');
      res[key] = val;
      return res;
    }, {});
}
