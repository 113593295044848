/**
 * This helper convert money value to user format and add currancy format
 * @param {Number} value currancy value
 * @param {String} sign currancy sign
 * @returns {String} Formatted in money format string with currancy sign
 */
export function toMoneyFormat(value = 0, sign = '$') {
  const prefix = value >= 0 ? sign : `-${sign}`;
  const strValue = Math.abs(value).toString().replace(/\d(?=(?:\d{3})+(?:\.|$))/g, '$&,');
  return `${prefix}${strValue}`;
}
