import { findItemIndexByKeyInArray } from '../findItemIndexByKeyInArray';

export function updateItem(src, key, val, keyName = 'id') {
  const index = findItemIndexByKeyInArray(src, key, keyName);
  return [
    ...src.slice(0, index),
    { ...src[index], ...val },
    ...src.slice(index + 1),
  ];
}
