import React, { Component, createRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { components } from 'Common';
const { Button } = components;

function modifyChild(children, ref) {
  return cloneElement(children, {
    ...children.props,
    ref,
  })
}

/**
 * Component, which wraps all pickers; it usualy calls from Picker `render`
 * method. It just add to Picker button, which opens it by click.
 */
export class PickerWrapper extends Component {

  ref = createRef();

  classNames = classnames('input-group__with-sm-button', this.props.className);
  child = cloneElement(this.props.children, {
    ...this.props.children.props,
    ref: this.ref,
  })

  handleBtnClick = () => {
    this.ref.current.openCalendar ? this.ref.current.openCalendar() : this.ref.current.focus();
  }

  render() {
    const { isInvalid, disabled } = this.props;

    return (
      <div className={this.classNames}>
        {modifyChild(this.props.children, this.ref)}
        <Button className={classnames('input-group__sm-button', {
          'input-group__sm-button--danger': isInvalid
        })}
          state={null} onClick={this.handleBtnClick} disabled={disabled}>
          <i className="fa fa-fw fa-calendar"></i>
        </Button>
      </div>
    );
  }
}


PickerWrapper.propTypes = {
  /* Instance of picker component */
  children: PropTypes.element,
  /* Adition container classes */
  className: PropTypes.string,
  /* If Picker value isn't walid, then open button should change color to red */
  isInvalid: PropTypes.bool,
  /* Name of icon, which should display on open calendar button */
  icon: PropTypes.string,
}

PickerWrapper.defaultProps = {
  icon: 'fa-calendar',
}
