import React from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
import { checkRequired } from 'Helpers/validators';
const { FormGroup } = components;

export class TeamMemberForm extends CommonForm {
  modelName = 'team_member'
  defaultItem = {
    first_name: '',
    last_name: '',
    nickname: '',
    role: '',
    ...(!!this.props.shirts && {
      shirt_style: '',
      shirt_size: ''
    }),
    team_id: this.props.team_id,
  }

  roles = this.props.team_member_roles.reduce(function (acc, cur) {
    acc[`${cur.name.replace(' ', '_').toLowerCase()}_id`] = cur.id;
    return acc;
  }, {})


  validators = {
    role: [checkRequired('role')],
    first_name: ({ item }) => {
      if (this.requireValidation(item)) {
        return this.checkValid('first_name', [checkRequired('first_name')])
      }
      return null;
    },
    last_name: ({ item }) => {
      if (this.requireValidation(item)) {
        return this.checkValid('last_name', [checkRequired('last_name')])
      }
      return null;
    },
    nickname: ({ item }) => {
      if (this.requireValidation(item)) {
        return this.checkValid('nickname', [checkRequired('nickname')])
      }
      return null;
    },
    shirt_style: ({ item }) => {
      if (this.requireValidation(item) && this.props.shirts) {
        return this.checkValid('shirt_style', [checkRequired('shirt_style')])
      }
      return null;
    },
    shirt_size: ({ item }) => {
      if (this.requireValidation(item) && this.props.shirts) {
        return this.checkValid('shirt_size', [checkRequired('shirt_size')])
      }
      return null;
    },
  }

  requireValidation = (item) => {
    return [this.roles.player_id, this.roles.captain_id].includes(Number(item.role))
  }

  get canChangeFieldsValue() {
    const { item } = this.state;

    return !this.isUserAdmin && Number(item.role) === this.roles.swa_pick_id;
  }

  get isUserAdmin() {
    return this.props.current_user.role === 'admin';
  }

  get serializedItem() {
    if (!this.canChangeFieldsValue) {
      return this.state.item;
    }

    const { team_id, role } = this.state.item

    return {
      team_id, role
    }
  }

  handleChangeFirstName = ({ target }) => this.changeValue('first_name', target.value);
  handleChangeLastName = ({ target }) => this.changeValue('last_name', target.value);
  handleChangeNickname = ({ target }) => this.changeValue('nickname', target.value);
  handleChangeRole = ({ target }) => this.changeValue('role', target.value);
  handleChangeShirtStyle = ({ target }) => this.changeValue('shirt_style', target.value);
  handleChangeShirtSize = ({ target }) => this.changeValue('shirt_size', target.value);
  get body() {
    const { item } = this.state;

    return (
      <div className="card card--size-md">
        <FormGroup label="Role" validationMessages={this.validationMessages.role}>
          <select className="input-group__input" value={item.role}
            onChange={this.handleChangeRole}>
            <option hidden></option>
            {this.props.team_member_roles.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </FormGroup>
        <FormGroup label="First Name" validationMessages={this.validationMessages.first_name}>
          <input type="text" className="input-group__input" value={item.first_name}
            onChange={this.handleChangeFirstName} disabled={this.canChangeFieldsValue} />
        </FormGroup>
        <FormGroup label="Last Name" validationMessages={this.validationMessages.last_name}>
          <input type="text" className="input-group__input" value={item.last_name}
            onChange={this.handleChangeLastName} disabled={this.canChangeFieldsValue} />
        </FormGroup>
        <FormGroup label="Nickname" validationMessages={this.validationMessages.nickname}>
          <input type="text" className="input-group__input" value={item.nickname}
            onChange={this.handleChangeNickname} disabled={this.canChangeFieldsValue} />
        </FormGroup>

       {this.props.shirts && 
          <>
            <FormGroup label="Shirt Style" validationMessages={this.validationMessages.shirt_style}>
              <select className="input-group__input" value={item.shirt_style}
                onChange={this.handleChangeShirtStyle} disabled={this.canChangeFieldsValue}>
                <option hidden></option>
                {this.props.shirt_styles.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                  ))}
              </select>
            </FormGroup>
            <FormGroup label="Shirt Size" validationMessages={this.validationMessages.shirt_size}>
              <select className="input-group__input" value={item.shirt_size}
                onChange={this.handleChangeShirtSize} disabled={this.canChangeFieldsValue}>
                <option hidden></option>
                {this.props.shirt_sizes.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                  ))}
              </select>
            </FormGroup>
          </>
        }
        {this.formFooter}
      </div>
    );
  }
}
