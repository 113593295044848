/**
 * Function move item inside array
 * @param {Array} src array of items, where item should be moved
 * @param {Number} from idex of item, which should be moved
 * @param {Number} to new item position
 * @returns {Array} new array of items
 */
export function moveInArray(src, from, to) {
  const item = src[from];
  if (to > from) {
    return [
      ...src.slice(0, from),
      ...src.slice(from + 1, to + 1),
      item,
      ...src.slice(to + 1),
    ];
  }

  if (from > to) {
    return [
      ...src.slice(0, to),
      item,
      ...src.slice(to, from),
      ...src.slice(from + 1),
    ];
  }
  return src;
}
