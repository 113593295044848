export const THEME = {
  button: {
    small: '',
    medium: '',
    large: '',
    primary: '',
    secondary: '',
    success: '',
    danger: '',
    warning: '',
    info: '',
    light: '',
    dark: '',
    link: '',
  },
  iconButton: {
    container: '',
  },
  table: {
    noDirectionButtonContent: '',
    ascButtonContent: '',
    descButtonContent: '',
    sortBtnClassName: '',
    sortBtnState: '',
  },

  formGroup: {
    container: '',
    child: '',
    childInvalid: '',
    validationMessage: '',
    label: '',
    labelRequired: '',
  },

  input: {
    control: '',
    small: '',
    medium: '',
    large: '',
    invalid: '',
  },
}
