import moment from 'moment';
import { DATE_TIME_FORMAT } from 'Constants/dateTime';
/**
 * Helper returns true or false if date in YYYY-MM-DD HH:mm format 
 * @param {string} date  
 */

export function isDateTimeValid(date) {
  return moment(date, DATE_TIME_FORMAT, true).isValid();
}

/**
 * Helper returns moment object if date in YYYY-MM-DD HH:mm format. otherwise - first prop
 * its need for DateTime component
 * @param {string} date must be iso date string 
 */

export function toMomentDate(date) {
  if (isDateTimeValid(date)) {
    return moment(date)
  }
  return date
}
