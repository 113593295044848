import React, { Fragment } from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
import RadioOptionGroup from 'Components/radioOptionGroup';
import { checkRequired } from 'Helpers/validators';

const { FormGroup, Button } = components;


export class TeamForm extends CommonForm {
  modelName = 'team';

  radioOptions = [
    {
      valueForActive: 'am/pm',
      value: 'am/pm',
      text: 'AM/PM'
    },
    {
      valueForActive: 'pm/am',
      value: 'pm/am',
      text: 'PM/AM'
    },
  ]

  constructor(props) {
    super(props);
  }

  validators = {
    name: [checkRequired('name')],
    preferred_start: [checkRequired('preferred_start')],
  }

  defaultItem = {
    name: '',
    preferred_start: 'am/pm'
  }

  handleChangeName = ({ target }) => this.changeValue('name', target.value);
  handleChangePreferredStart = ({ target }) => this.changeValue('preferred_start', target.value);

  handleSetEditMode = () => this.setState({ isEditMode: true });
  handleCancelEditMode = () => {
    this.setState({ isEditMode: false, item: { ...this.props.team, name: this.props.team.name || '' } });
  }
  afterSubmit = () => {
    this.setState({ isEditMode: false });
  }

  render() {
    const { item, isEditMode } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup label="Team Name" isRequired validationMessages={this.validationMessages.name}>
          <div className="input-group__with-sm-button">
            <input type="text" className="input-group__input" value={item.name}
              onChange={this.handleChangeName} disabled={!isEditMode} />
            {isEditMode && (
              <Fragment>
                <Button className="input-group__sm-button
                  input-group__sm-button--border_theme"
                  state={null} onClick={this.handleCancelEditMode}>
                  <i className="fa fa-undo"></i>
                </Button>
                <Button className="input-group__sm-button" type="submit"
                  state={null}>
                  <i className="fa fa-save"></i>
                </Button>
              </Fragment>
            )}
            {!isEditMode && (
              <Button className="input-group__sm-button"
                state={null} onClick={this.handleSetEditMode}>
                <i className="fas fa-edit"></i>
              </Button>
            )}
          </div>
        </FormGroup>
        <FormGroup label="Preferred Start (not all request can be accommodated)" validationMessages={this.validationMessages.preferred_start}>
          <RadioOptionGroup onChange={this.handleChangePreferredStart}
            value={item.preferred_start} radioOptions={this.radioOptions}
            disabled={!isEditMode} />
        </FormGroup>
      </form>
    );
  }

}
