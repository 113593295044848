import React, { Fragment, } from 'react';
import { CommonForm } from 'Components';
import { checkRequired } from 'Helpers/validators';
import { components } from 'Common';
const { FormGroup, Button } = components;


export class PurchaseForUserForm extends CommonForm {

  defaultItem = {
    user_id: ''
  }

  validators = {
    user_id: [checkRequired('user_id')],
  }

  get serializedItem() {
    const { user_id } = this.state.item;
    const { purchased_memberships, purchased_shirts } = this.props
    return {
      order: {
        user_id: Number(user_id),
        paid: true,
        purchased_items: [
          ...purchased_memberships,
          ...purchased_shirts,
        ]
      }
    }
  }

  handleChangeUserId = ({ target }) => this.changeValue('user_id', target.value);

  render() {
    const { users, disabled } = this.props;
    const { item } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="card mb-20">
        <h2 className="card__title">Admin can assign memberships for user without payment in Stripe</h2>
        <p>Select memberships / t-shirts and quantity. Choose user and click <strong>assign</strong></p>
        <div className="input-with-button" style={{ maxWidth: '750px' }}>
          <FormGroup label="User" isRequired
            validationMessages={this.validationMessages.user_id} className="w-100">
            <select className="input-group__input" value={item.user_id}
              onChange={this.handleChangeUserId}>
              <option hidden></option>
              {users.map(({ id, first_name, last_name, email }) => (
                <option key={id} value={id}>{`${first_name} ${last_name} (${email})`}</option>
              ))}
            </select>
          </FormGroup>
          <div className="button-wrapper input-with-button__button-wrapper">
            <div className="input-group w-100 pl-20">
              <span className="input-group__label">&nbsp;</span>
              <Button type="submit" className="button-wrapper__button--sm_height"
                disabled={disabled}>
                assign
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
