/**
 *
 * @param {Array} src array of items
 * @param {*} key Value, which should be contained by item
 * @param {String} keyName Name of key, which should contain value
 * @returns {Number} index of item in src. If there is no item, should return -1
 */
export function findItemIndexByKeyInArray(src, key, keyName = 'id') {
  return src.findIndex(item => item[keyName] === key);
}
