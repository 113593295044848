import React, { forwardRef, useContext, } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';

export const Textarea = forwardRef(({ className, invalid, size, ...inputProps }, ref) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'textarea');
  const textareaClassName = classnames(className, context[size], {
    [context[invalid]]: invalid,
  });
  return (
    <textarea {...inputProps} className={textareaClassName} />
  );
});

Textarea.propTypes = {
  invalid: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large',]),
};

Textarea.defaultProps = {
  size: 'medium',
};
