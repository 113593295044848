import React, { Fragment } from 'react';
import { CommonList } from 'Components';
import { toMoneyFormat } from 'Helpers/toMoneyFormat';

export class MyDonations extends CommonList {

  get tableConfig() {
    return [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Quantity',
        accessor: 'quantity',
      },
      {
        title: 'Price per item',
        accessor: 'price',
        cellFn: ({ item }) => toMoneyFormat(item.price),
      },
      {
        title: 'Amount',
        acessor: 'total_price',
        cellFn: ({ item }) => toMoneyFormat(item.total_price),
      },
      {
        title: 'Teams',
        accessor: 'teams',
        cellFn: ({ item }) => item.teams.map(({ url, name, }) => (
          <Fragment>
            <a target="_blank" href={url} key={url} className="link">{name}</a>
            &nbsp;
          </Fragment>
        )),
      }
    ];
  }
}
