import React, { Component, createRef, } from 'react';
import PropTypes from 'prop-types';
import { helpers } from 'Common';

const { AJAX } = helpers;

export class CommonList extends Component {
  static propTypes = {
    fetchMethod: PropTypes.oneOf(['get', 'post']),
    defaultSortColumn: PropTypes.string,
    defaultPerPage: PropTypes.number,
    initialState: PropTypes.object,
    onSuccessFetch: PropTypes.func,
    hasPagination: PropTypes.bool,
  };

  static defaultProps = {
    fetchMethod: 'get',
    defaultSortColumn: 'name',
    defaultPerPage: 50,
    hasPagination: true,
  };
  fetchOnMount = true
  filters = createRef();
  warning = createRef();

  constructor(props) {
    super(props);
    this.dataUrl = props.fetch_url;
    this.destroyUrl = props.destroy_url || props.fetch_url;
    this.state = {
      itemToRemove: null,
      items: [],
      loading: true,
      ...(props.hasPagination) && {
        pagination: {
          page: 1,
          sizePerPage: props.defaultPerPage,
          recordsFiltered: 0,
          recordsTotal: 0,
        }
      },
      sorting: {
        column: props.defaultSortColumn,
        direction: 'asc',
      },
      search: '',
      ...props.initialState,
    };
    this.fetchData = this.fetchData.bind(this);
    this.serializeData = this.serializeData.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.getRequestBody = this.getRequestBody.bind(this);
  }

  componentDidMount() {
    if (this.fetchOnMount) {
      this.fetchData();
    }
  }

  getRequestBody(params) {
    let body = {
      ...this.sortingParams,
      ...this.paginationParams,
      search: this.state.search ? { value: this.state.search } : undefined,
      ...params,
    };
    if (!!this.filters.current) {
      body.filter = this.filters.current.serialize();
    }

    return body;
  }

  fetchData(params) {
    const request = AJAX[this.props.fetchMethod](this.dataUrl, {
      body: this.getRequestBody(params),
    });
    request.then(res => {
      if (!!this.props.onSuccessFetch) {
        this.props.onSuccessFetch(res);
      }
      this.setState(this.serializeData(res));
    })
    this.setState({ loading: true });
    return request;
  }

  handleChangeSort(column, direction = 'asc') {
    this.setState(
      { sorting: { column, direction, } },
      () => this.fetchData()
    );
  }

  handleChangePagination = (pagination) => this.setState(
    { pagination: { ...this.state.pagination, ...pagination } },
    () => this.fetchData()
  );

  handleRemoveItem = ({ id }) => {
    if (!!confirm('Are you sure?')) {
      debugger;
      const url = `${this.destroyUrl}/${id}`;
      const request = AJAX.delete(url);
      debugger;
      request.then(() => {
        debugger;
        this.setState({ itemToRemove: null });
        this.fetchData();
      });
      return request;
    }
  }

  handleChangeSearch = ({ target }) => {
    this.setState({
      search: target.value,
      pagination: { ...this.state.pagination, page: 1 },
    }, () => this.fetchData())
  };

  serializeData({ data, recordsTotal, recordsFiltered }) {
    return {
      items: data,
      pagination: { ...this.state.pagination, recordsTotal, recordsFiltered },
      loading: false,
    };
  }

  get paginationParams() {
    if (!this.props.hasPagination) {
      return null;
    }
    const { page, sizePerPage } = this.state.pagination;
    return { size_per_page: sizePerPage, page };
  }

  get sortingParams() {
    const { column, direction, } = this.state.sorting;
    return { sort_column: column, sort_direction: direction, };
  }
}
