import React, { Fragment } from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';

const { Button, FormGroup } = components;

export class ShirtRowForm extends CommonForm {

  defaultItem = {
    id: this.props.id,
    item_id: this.props.shirtId,
    quantity: '',
    size: this.props.options.size[0],
    style: this.props.options.style[0],
  }

  handleChangeQuantity = ({ target }) => this.changeValue('quantity', target.value);
  handleChangeStyle = ({ target }) => this.changeValue('style', target.value);
  handleChangeSize = ({ target }) => this.changeValue('size', target.value);


  handleAddToCart = () => {
    this.props.addToCart(this.state.item)
  }

  handleRemoveFromCart = () => {
    const { item } = this.state
    this.props.removeFromCart(item.id);
  }

  get isAvailableQuantity() {
    const { item } = this.state;
    const { cartQuantity } = this.props;

    return Number(item.quantity) > cartQuantity
  }

  get canAddToCart() {
    const { item } = this.state;

    return !!item.quantity
  }

  render() {
    const { item } = this.state;
    const { options, isItemInCart, cartQuantity } = this.props;

    return (
      <Fragment>
        <div className="row-generate row-generate--generated">
          <Button state={null} className="row-generate__delete"
            onClick={this.handleRemoveFromCart}></Button>
          <div className="row-generate__coll">
            <FormGroup label="Quantity" >
              <input type="number" className="input-group__input"
                onChange={this.handleChangeQuantity} value={item.quantity}
                disabled={isItemInCart} />
            </FormGroup>
          </div>
          <div className="row-generate__coll">
            <FormGroup label="Style" >
              <select className="input-group__input"
                onChange={this.handleChangeStyle} value={item.style}
                disabled={isItemInCart}>
                {options.style.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </FormGroup>
          </div>
          <div className="row-generate__coll">
            <FormGroup label="Size" >
              <select className="input-group__input"
                onChange={this.handleChangeSize} value={item.size}
                disabled={isItemInCart}>
                {options.size.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </FormGroup>
          </div>
          {!isItemInCart && (
            <div className="row-generate__coll">
              <div className="input-group">
                <label className="input-group__label">&nbsp;</label>
                <div className="button-wrapper" style={{ paddingTop: 0 }}>

                  <Button className="button-wrapper__button--full-width button-wrapper__button--sm_height"
                    onClick={this.handleAddToCart}
                    disabled={!this.canAddToCart || this.isAvailableQuantity}>
                    Add To Cart
                  </Button>
                </div>
                <p className="input-group__error">
                  {this.canAddToCart && this.isAvailableQuantity &&
                    (`Available quantity: ${cartQuantity}`)}
                </p>
              </div>
            </div>)}

        </div>
      </Fragment>
    )
  }
}
