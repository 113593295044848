import React, { Fragment } from 'react';
import classnames from 'classnames';

const PAGE_SIZES = [10, 25, 50, 100];

const NavButton = ({ current, pageNum, onClick, size }) => {
  const handleClick = () => onClick({ page: pageNum, size, });
  return (
    <li className={classnames('pagination-list__item pagination-list-item ',
      { 'pagination-list-item--active': !!current })}>
      <button className="pagination-list-item__button" onClick={handleClick}>{pageNum}</button>
    </li>
  );
};

const NavButtons = ({ page, sizePerPage, recordsTotal, maxPages, onChange, showSideButtons, startButtonContent, endButtonContent }) => {
  const pagesTotal = Math.ceil(recordsTotal / sizePerPage);
  if (pagesTotal <= 1) {
    return null;
  }

  let minPage = Math.max(page - Math.floor(maxPages / 2), 1);
  let maxPage = minPage + maxPages - 1;
  if (maxPage > pagesTotal) {
    maxPage = pagesTotal;
    minPage = Math.max(pagesTotal - maxPages, 1);
  }

  const buttons = Array.apply(null, { length: maxPage - minPage + 1 }).map((value, i) => {
    const index = minPage + i;

    return (
      <NavButton key={`pagination-button-${index}`} size={sizePerPage}
        onClick={onChange} current={index === page} pageNum={index} />
    );
  });

  return (
    <ul className="pagination-list">
      {!!showSideButtons && minPage > 1 && (
        <Fragment>
          <NavButton onClick={onChange} size={sizePerPage} pageNum={1}>
            {startButtonContent}
          </NavButton>
          {minPage - 1 > 1 && (
            <li className="pagination-list__item">
              <span className="pagination-list__dots">...</span>
            </li>
          )}
        </Fragment>
      )}
      {buttons}
      {!!showSideButtons && maxPage < pagesTotal && (
        <Fragment>
          {maxPage < pagesTotal - 1 && (
            <li className="pagination-list__item">
              <span className="pagination-list__dots">...</span>
            </li>
          )}
          <NavButton size={sizePerPage} onClick={onChange} pageNum={pagesTotal}>
            {endButtonContent}
          </NavButton>
        </Fragment>
      )}
    </ul>
  );
}

const Paginator = (props) => {
  const handleChangePageSize = ({ target }) => {
    props.onChange({ sizePerPage: Number(target.value), page: 1, });
  }

  return (
    <div className="pagination-wrapper">
      <div className="pagination-wrapper__side">
        <div className="input-group pagination-wrapper__select">
          <select className="input-group__input" onChange={handleChangePageSize}
            value={props.sizePerPage}>
            {PAGE_SIZES.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="pagination-wrapper__side">
        <span className="pagination-wrapper__total">Total: {props.recordsTotal}</span>
        <NavButtons {...props} />
      </div>
    </div>
  );
}

export default Paginator;
