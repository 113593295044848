/**
 *
 * @param {Array} src
 * @param {Object} item
 * @param {String} key
 * @returns {Array}
 */
export function replaceItemInArray(src, item, key = 'id') {
  const index = findItemIndexByKey(src, item[key], key);
  return [
    ...src.slice(0, index),
    item,
    ...src.slice(index + 1),
  ];
}
