import React from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
import { DatePicker } from 'Components/Picker';
import MembershipsOrderingForm from './MembershipsOrderingForm.js';

const { FormGroup } = components;

export class TournamentForm extends CommonForm {
  modelName = 'tournament';

  defaultItem = {
    name: '',
    date: '',
    payment_account_id: '',
    description: '',
    status: 'inactive',
  }

  handleChangeName = ({ target }) => this.changeValue('name', target.value);
  handleChangeDate = (value) => this.changeValue('date', value);
  handleChangePaymentAccount = ({ target }) => this.changeValue('payment_account_id', target.value);
  handleChangeDescription = ({ target }) => this.changeValue('description', target.value);
  handleChangeStatus = ({ target }) => this.changeValue('status', target.value);
  get body() {
    const { item } = this.state;

    return (
      <div className="card card--size-md">
        <FormGroup label="Name" isRequired
          validationMessages={this.validationMessages.name}>
          <input type="text" className="input-group__input" value={item.name}
            onChange={this.handleChangeName} />
        </FormGroup>
        <FormGroup label="Date" isRequired
          validationMessages={this.validationMessages.date}>
          <DatePicker value={item.date} onChange={this.handleChangeDate} />
        </FormGroup>
        <FormGroup label="Payment Account">
          <select className="input-group__input" value={item.payment_account_id}
            onChange={this.handleChangePaymentAccount}>
            <option hidden></option>
            {this.props.payment_accounts.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
        </FormGroup>
        <FormGroup label="Description">
          <textarea value={item.description} className="input-group__input"
            onChange={this.handleChangeDescription}>
          </textarea>
        </FormGroup>
        <FormGroup label="Status">
          <select className="input-group__input" value={item.status}
            onChange={this.handleChangeStatus}>
            <option value='inactive'>Inactive</option>
            <option value='active'>Active</option>
          </select>
        </FormGroup>
        {this.formFooter}
        {!!item.id && (
          <MembershipsOrderingForm fetch_url={this.props.memberships_list_api_url}/>
        )}
      </div>
    );
  }
}
