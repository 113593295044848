import React, { Fragment, createRef } from 'react';
import { CommonList } from 'Components';
import { components, helpers } from 'Common';
import { TeamMemberForm } from './form_row';
import Preloader from 'Components/Preloader';

const { Button } = components;
const { AJAX } = helpers;

class SortButton extends React.Component {

  handleClick = () => {
    const { accessor, sorting, onSortChange } = this.props;

    let direction = 'desc';
    if (accessor === sorting.column) {
      direction = sorting.direction === 'desc' ? 'asc' : 'desc';
    }
    onSortChange(accessor, direction);
  }

  get icon() {
    const { accessor, sorting } = this.props;

    if (sorting.column !== accessor) {
      return (<i className="fas fa-sort"></i>)
    }

    if (sorting.direction === 'asc') {
      return (<i className="fas fa-sort-up"></i>)
    }

    return (<i className="fas fa-sort-down"></i>)

  }

  render() {
    return (
      <button className="sort-button" type="button" onClick={this.handleClick} >
        {this.icon}
      </button>
    )
  }
}

export class TournamentManagement extends CommonList {

  shirtsList = createRef();

  state = {
    ...this.state,
    filter: {
      tournaments: 'all',
      teams: 'all'
    },
    teams: this.props.teams
  }

  fetchData = () => {
    const { tournaments, teams } = this.state.filter
    const filter = {
      tournaments: tournaments === 'all' ? this.props.tournaments.map(({ id }) => id) : [tournaments],
      teams: teams === 'all' ? this.props.teams.map(({ id }) => id) : [teams]
    }

    super.fetchData({ filter });
  }

  handleChangeTournamentFilter = ({ target }) => {
    let tournamentTeams = this.props.teams;

    if (target.value != 'all') {
      tournamentTeams = tournamentTeams.filter((team) => { return team.tournament_id == target.value });
    }

    this.setState({ teams: tournamentTeams });
    this.handleChangeFilter('tournaments', target.value);
  }

  handleChangeTeamFilter = ({ target }) => {
    this.handleChangeFilter('teams', target.value);
  }

  handleExportList = async () => {
    const { tournaments, teams } = this.state.filter;
    const filter = {
      tournaments: tournaments === 'all' ? this.props.tournaments.map(({ id }) => id) : [tournaments],
      teams: teams === 'all' ? this.props.teams.map(({ id }) => id) : [teams]
    }

    const res = await AJAX.post(this.props.export_url, {
      body: {
        filter,
        sort_column: this.state.sorting.column,
        sort_direction: this.state.sorting.direction
      }
    });

    if (!!res.csv_link) {
      window.location.href = res.csv_link
    }
  }

  get table() {
    const { team_member_roles, api_url } = this.props;
    const { items, loading } = this.state;

    if (!!loading) {
      return (<Preloader />);
    }

    return (
      <div className="table table--custom_table">
        <div className="table__head">
          <div className="table__th">
            Team Name
            <SortButton accessor="team_name" sorting={this.state.sorting}
              onSortChange={this.handleChangeSort} />
          </div>
          <div className="table__th">Role</div>
          <div className="table__th">First Name</div>
          <div className="table__th">Last Name</div>
          <div className="table__th">
            Time
            <SortButton accessor="preferred_start" sorting={this.state.sorting}
              onSortChange={this.handleChangeSort} />
          </div>
          <div className="table__th">Membership</div>
          <div className="table__th">
            Start
            <SortButton accessor="starting_hole" sorting={this.state.sorting}
              onSortChange={this.handleChangeSort} />
          </div>
          <div className="table__th">
            Position
            <SortButton accessor="position" sorting={this.state.sorting}
              onSortChange={this.handleChangeSort} />
          </div>
          <div className="table__th actions">Actions</div>
        </div>
        <div className="table__body">
          {items.map((item) => (
            <TeamMemberForm key={item.id} team_member={item} api_url={api_url}
              roles={team_member_roles} fetchData={this.fetchData} />
          ))}
        </div>
      </div>
    );
  }



  render() {
    const { name, tournaments } = this.props;

    return (
      <Fragment>
        <h1 className="system__title system__title--with-button">
          {name}
          <Button type="button" className="system__title-link button-wrapper__button--auto-width" onClick={this.handleExportList}>
            Export csv
          </Button>
        </h1>
        <div className="card">
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <select className="input-group__input" value={this.state.filter.tournaments}
              onChange={this.handleChangeTournamentFilter}>
              <option value='all'>All Tournaments</option>
              {tournaments.map(({ name, id }) => (
                <option value={id} key={id}>{name}</option>
              ))}
            </select>
            <select className="input-group__input" value={this.state.filter.teams}
              onChange={this.handleChangeTeamFilter}>
              <option value='all'>All Teams</option>
              {this.state.teams.map(({ name, id }) => (
                <option value={id} key={id}>{name}</option>
              ))}
            </select>
          </div>
          <div className="table-wrapper table-wrapper--custom_table">
            {this.table}
          </div>
          {this.pagination}
        </div>
      </Fragment>
    );
  }
}
