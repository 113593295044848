import { constants } from 'Common';
import React from 'react';

export const THEME = {
  ...constants.THEME,
  button: {
    primary: 'button-wrapper__button',
    light: 'button-wrapper__button button-wrapper__button--border_theme'
  },
  formGroup: {
    container: 'input-group mb-20',
    validationMessage: 'input-group__error',
    childInvalid: 'input-group__input--error',
    label: 'input-group__label'
  },

  table: {
    ascButtonContent: 'q111',
    descButtonContent: 'q222',
    sortBtnClassName: 'sort-button',
    sortBtnState: '',
    ascButtonContent: (<i className="fas fa-sort-up"></i>),
    descButtonContent: (<i className="fas fa-sort-down"></i>),
    noDirectionButtonContent: (<i className="fas fa-sort"></i>),
  },
  iconButton: {
    container: 'icon-button',
  },
}
