import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Datetime from 'react-datetime';
import moment from 'moment';
import {
  DISPLAY_DATE_FORMAT, DATE_FORMAT,
} from 'Constants/dateTime';
import { PickerWrapper } from './Wrapper';
import 'react-datetime/css/react-datetime.css';

export const DatePicker = (
  { onChange, value, format, className, minDate,
    maxDate, disabled, ...props }) => {

  /**
   * Callback for change Datetime control. Here we check, if value has `format`
   * method (which means, that date was selected from calendar, not entered from
   * keyboard)/ then we format it to default date format and send to `onChange`
   * callback, else we send just value from input;
   * @param {String} value value from Datetime input
   */
  function handleChange(value) {
    onChange(value.format ? value.format(DATE_FORMAT) : value);
  }

  const classNames = classnames('input-group__date-wrapper', className);
  /**
   * here we check, if value is shorter, than date format, then we disabling
   * formatting date value, else, for example, moment convert `'0'` to `'01/01/2000'`
   */
  if (!!value && value.length >= format.length) {
    value = moment(value).format(format);
  }

  function isValidDate(current) {
    if (minDate || maxDate) {
      const momentMinDate = minDate && Datetime.moment(minDate).subtract(1, 'day');
      const momentMaxDate = maxDate && Datetime.moment(maxDate).add(1, 'day');

      return (!momentMinDate || current.isAfter(momentMinDate)) &&
        (!momentMaxDate || current.isBefore(momentMaxDate))
    }

    return true
  }

  return (
    <PickerWrapper isInvalid={props.invalid} disabled={disabled}>
      <Datetime {...props} isValidDate={isValidDate}
        className={classNames} value={value}
        onChange={handleChange}
        dateFormat={format} timeFormat={false}
        inputProps={{
          disabled,
          className: classnames('input-group__input', {
            'input-group__input--error': !!props.invalid
          })
        }}
        closeOnSelect />
    </PickerWrapper>
  )
};

DatePicker.propTypes = {
  /* display date format */
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /* on change callback function, get string with DatePicker's value as argument */
  onChange: PropTypes.func,
  /* DatePicker addition classes */
  className: PropTypes.string,
};

DatePicker.defaultProps = {
  format: DISPLAY_DATE_FORMAT,
}
