import { helpers } from 'Common';

const { getObjectValue } = helpers;

export function checkRequired(key, msg = 'can\'t be blank') {
  return function ({ item }) {
    const val = getObjectValue(item, key);
    if (!val || val.length === 0) {
      return [msg];
    }
    return null;
  }
}
