import React, { Fragment } from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';

const { Button, FormGroup } = components;

export class MembershipCartForm extends CommonForm {

  defaultItem = {
    quantity: '',
  }

  quantityErrors = null;

  handleChangeQuantity = ({ target }) => {
    const quantity = Number(target.value);
    this.quantityErrors = quantity < 1 ? ['quantity should be positive'] : null;
    this.changeValue('quantity', quantity);
  }

  handleAddToCart = () => {
    const { itemId, addToCart } = this.props;
    const { quantity } = this.state.item;
    if (quantity <= 0) {
      return;
    }
    addToCart({
      item_id: itemId,
      quantity,
    })
  }

  handleRemoveFromCart = () => {
    this.props.removeFromCart(this.props.itemId)
  }

  get isAvailableQuantity() {
    const { item } = this.state;
    const { availableQuantity } = this.props;

    return item.quantity > availableQuantity;
  }

  get isSoldOut() {
    const { availableQuantity } = this.props;

    return availableQuantity === 0;
  }

  render() {
    const { availableQuantity, isItemInCart } = this.props
    const { item } = this.state;
    return (
      <Fragment>
        <div className="input-group ">
          <FormGroup label="Quantity" className="no-margin" validationMessages={this.quantityErrors}>
            <input type="number" className="input-group__input"
              onChange={this.handleChangeQuantity} value={item.quantity}
              disabled={isItemInCart || this.isSoldOut} />
          </FormGroup>
          <p className="input-group__error">
            {this.isAvailableQuantity && (`Available quantity: ${availableQuantity}`)}
          </p>
        </div>
        <div className="button-wrapper button-wrapper--less_dispance">
          {!isItemInCart && (
            <Button className="button-wrapper__button--auto-width button-wrapper__button--sm_height"
              onClick={this.handleAddToCart} disabled={!item.quantity || this.isAvailableQuantity}>
              {!this.isSoldOut ? 'Add To Cart' : 'Sold Out'}
            </Button>
          )}
          {isItemInCart && (
            <Button
              className="button-wrapper__button--auto-width button-wrapper__button--green button-wrapper__button--sm_height"
              onClick={this.handleRemoveFromCart} >
              remove from Cart
            </Button>
          )}
        </div>
      </Fragment>
    )
  }
}
