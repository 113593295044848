import React, { Fragment } from 'react';
import { components } from 'Common';
const { Button } = components;

export const UserShow = ({ user, users_url }) => (
  <Fragment>
    <h1 className="system__title system__title--with-button">
      {user.first_name} {user.last_name}
      <Button type="link" href={users_url}
        className="system__title-link button-wrapper__button--auto-width">
        Back
      </Button>
    </h1>
    <div className="card">
      <div className="table-wrapper">
        <table className="table">
          <tbody className="table__body">
            <tr className="table__tr">
              <td className="table__td">
                <strong>Email:</strong>
              </td>
              <td className="table__td">{user.email}</td>
            </tr>
            <tr className="table__tr">
              <td className="table__td">
                <strong>First Name:</strong>
              </td>
              <td className="table__td">{user.first_name}</td>
            </tr>
            <tr className="table__tr">
              <td className="table__td">
                <strong>Last Name:</strong>
              </td>
              <td className="table__td">{user.last_name}</td>
            </tr>
            <tr className="table__tr">
              <td className="table__td">
                <strong>Organization:</strong>
              </td>
              <td className="table__td">{user.organization}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Fragment>
);
