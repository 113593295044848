export function insertToObject(res, path, val) {
  if (path.length === 1) {
    return { ...res, [path[0]]: val, };
  }
  const [key, ...newPath] = path;
  const newRes = res[key];
  return {
    ...res,
    [key]: insertToObj(newRes, newPath, val)
  };
}
