import React, { forwardRef, useContext, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';


export const Button = forwardRef(({ type, size, state, outline, className, ...props }, ref) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'button');
  const finalClassName = classnames(
    className,
    context[size],
    {
      [context[state]]: state,
      [context.outline]: outline,
      [context.disabled]: props.disabled,
    },
  );

  if (type === 'label') {
    return (
      <span ref={ref} className={finalClassName} {...props}></span>
    );
  }

  if (type === 'link') {
    return (
      <a ref={ref} className={finalClassName} {...props}></a>
    );
  }

  return (
    <button ref={ref} className={finalClassName} type={type} {...props}></button>
  );
});

Button.propTypes = {
  /** Is button disabled */
  disabled: PropTypes.bool,
  /** Button type */
  type: PropTypes.oneOf(['button', 'submit', 'link', 'label']),
  /** responsible for button background */
  state: PropTypes.oneOf([
    'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link', null, ''
  ]),
  /** Button size */
  size: PropTypes.oneOf(['small', 'medium', 'large',]),
  /** Click button callback */
  onClick: PropTypes.func
};

Button.defaultProps = {
  type: 'button',
  state: 'primary',
  size: 'medium',
}
