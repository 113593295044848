import React, { Fragment, Component } from 'react';
import { components, helpers } from 'Common';
import { ShirtRowForm } from './shirt_row_form';


const { Button } = components;
const { removeFromArray, findItemIndexByKeyInArray } = helpers;
export class ShirtCartRows extends Component {

  state = {
    shirts: [],
    cartQuantity: 0
  }

  addRow = () => {
    this.setState({
      shirts: [
        ...this.state.shirts,
        {
          id: Math.random()
        }
      ]
    });
  }

  removeFromCart = (id) => {
    let { shirts } = this.state;
    const itemIndex = findItemIndexByKeyInArray(shirts, id);

    shirts = removeFromArray(shirts, itemIndex);

    this.setState({
      shirts,
      cartQuantity: this.getShirdsSum(shirts),
    }, this.props.changeCanSubmit)
  }

  addToCart = (item) => {
    const { id } = item;
    let { shirts } = this.state;

    const itemIndex = findItemIndexByKeyInArray(shirts, id);

    shirts = [
      ...shirts.slice(0, itemIndex),
      item,
      ...shirts.slice(itemIndex + 1),
    ]

    this.setState({
      shirts,
      cartQuantity: this.getShirdsSum(shirts)
    }, this.props.changeCanSubmit);
  }

  getShirdsSum(shirts) {
    return shirts.reduce((sum, currentValue) => {
      return currentValue ? sum + Number(currentValue.quantity) : sum
    }, 0);
  }

  render() {
    const { shirts, cartQuantity } = this.state;
    const { options, itemId, availableQuantity } = this.props;

    return (
      <Fragment>
        <div className="add-row-generate">
          <Button state={null} className="add-row-generate__btn" onClick={this.addRow}></Button>
          <p className="add-row-generate__text">Add Shirt</p>
        </div>
        <div>
          {shirts.map((item) => (
            <ShirtRowForm key={item.id} id={item.id} shirtId={itemId}
              options={options} addToCart={this.addToCart} removeFromCart={this.removeFromCart}
              isItemInCart={!!item.item_id}
              cartQuantity={availableQuantity - cartQuantity} />
          ))}
        </div>
      </Fragment>

    )
  }
}
