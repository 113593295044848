import React from 'react';
import classnames from 'classnames';

const Radio = ({ item, value, handleChange, disabled }) => {
  const { valueForActive, text } = item;
  const id = `checkbox-${Math.random() * 1000}`;
  return (
    <div className="flex-actions__item radio-wrapper">
      <div className="radio-wrapper__check-style">
        <input type="radio" className="radio-wrapper__radio"
          id={id} checked={value === valueForActive} value={item.value}
          onChange={handleChange} disabled={disabled} />
        <label htmlFor={id}></label>
      </div>
      <p className={classnames('radio-wrapper__label',
        { 'radio-wrapper__label--disabled': disabled })}>{text}</p>
    </div>
  )
}

const RadioOptionGroup = ({ onChange, value, radioOptions = [], disabled = false, className }) => {
  return (
    <div className={`flex-actions ${className}`}>
      {radioOptions.map((item) => (
        <Radio key={item.valueForActive} item={item} value={value} handleChange={onChange} disabled={disabled} />))}
    </div>
  )
}


export default RadioOptionGroup;
