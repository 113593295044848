export function removeFromArray(src, index) {
  if (index === -1) {
    return src;
  }

  return [
    ...src.slice(0, index),
    ...src.slice(index + 1),
  ];
}
