import React, { Fragment } from 'react';
import { CommonList } from 'Components';
import { components, helpers } from 'Common';

const { Button } = components;
const { AJAX, toQueryString } = helpers;

export class Users extends CommonList {

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedUsers: {},
    }
  }

  get isVisibleUsersSelected() {
    return Object.values(this.state.selectedUsers).length === this.state.items.length
  }

  handleSelectUser = ({ target }, id) => {
    const { selectedUsers } = this.state

    const { [id]: currentId, ...ids } = selectedUsers

    this.setState({
      selectedUsers: {
        ...ids,
        ...(target.checked && { [id]: true })
      }
    })
  }

  onVisibleUsersSelect = () => {
    const selectedUsers = this.state.items.reduce((users, user) => ({
      ...users,
      [user.id]: true
    }), {})
    this.setState({ selectedUsers })
  }

  onVisibleUsersDeselect = () => {
    this.setState({ selectedUsers: {} })
  }

  onSelectUsersToogle = () => {
    this.isVisibleUsersSelected ?
      this.onVisibleUsersDeselect() :
      this.onVisibleUsersSelect()
  }

  handleDeleteMultipleUsers = async () => {
    if (!!confirm('Are you sure?')) {
      await AJAX.delete(`${this.props.bulk_destroy_url}?${toQueryString({ ids: Object.keys(this.state.selectedUsers) })}`, this.additionParams);

      this.setState({
        pagination: { ...this.state.pagination, page: 1 },
        selectedUsers: {},
      }, this.fetchData)
    }
  }

  get tableConfig() {
    return [
      {
        title: 'Name',
        accessor: 'name',
        sortable: true,
        cellFn: ({ item }) => (
          <div className="flex-actions">
            <div className="checkbox-wrapper flex-actions__line-item" >
              <div className="checkbox-wrapper__check-style">
                <input type="checkbox"
                checked={!!this.state.selectedUsers[item.id]}
                value={this.state.selectedUsers[item.id]}
                onChange={(e) => { this.handleSelectUser(e, item.id) }}
                className="checkbox-wrapper__checkbox"
                id={item.id} />
                <label htmlFor={item.id}></label>
              </div>
            </div>
            <a className="link" href={`${this.props.url}/${item.id}`}>{item.name}</a>
          </div>
        )
      },
      {
        title: 'Email',
        accessor: 'email',
      },
      {
        title: 'Organization',
        accessor: 'organization'
      },
      this.actionsColumn,
    ];
  }

  get addons() {
    const { selectedUsers } = this.state

    return (
      <Fragment>
          <div className="button-wrapper button-wrapper--select_theme mb-20">
            <div className="flex-actions">
              <div className="checkbox-wrapper flex-actions__line-item" >
                <div className="checkbox-wrapper__check-style">
                  <input type="checkbox"
                    checked={!!this.isVisibleUsersSelected}
                    value={this.isVisibleUsersSelected}
                    onChange={this.onSelectUsersToogle}
                    className="checkbox-wrapper__checkbox"
                    id='check-all'
                    />
                  <label htmlFor='check-all'></label>
                </div>
              </div>
              <span>Select all</span>
            </div>
            {!!Object.keys(selectedUsers).length && (
              <Button type="button" onClick={this.handleDeleteMultipleUsers} className="button-wrapper__button--auto-width">
                Delete Selected Users
              </Button>
            )}
          </div>
      </Fragment>
    );
  }

}
