import React from 'react';
import { CommonList } from 'Components';
export class TournamentMemberships extends CommonList {
  get tableConfig() {
    return [
      {
        title: 'Tournament Membership Name',
        accessor: 'name',
        sortable: true,
        cellFn: ({ item }) => (
          <a className="link" href={`${this.props.url}/${item.id}/edit`}>{item.name}</a>
        )
      },
      {
        title: 'Sponsorship Package',
        accessor: 'price',
        sortable: false,
      },
      {
        title: 'Tournament',
        accessor: 'tournament',
        sortable: true,
        cellFn: ({ item }) => (
          <a className="link" href={`${this.props.url}/${item.id}/edit`}>{item.tournament}</a>
        )
      },
      this.actionsColumn,
    ];
  }
}
