/**
 * Helper receive object and path to value, separated by docs and return value,
 * which situated by this path
 * @param {object} obj object, where is our value
 * @param {string} key path to value
 * @returns {*} value, which we search for
 */
export function getObjectValue(obj, key) {
  const res = key.split('.').reduce(function (res, key) {
    return res[key] || {};
  }, obj);
  if (Object.entries(res).length === 0 && res.constructor === Object) {
    return undefined;
  }
  return res;
}
