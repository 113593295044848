import React, { cloneElement, useContext } from 'react';
import classnames from 'classnames';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';

function modifyChild({ children, validationMessages, highlightChildren, context }, otherProps) {
  const className = classnames(
    children.props ? children.props.className : '',
    context.child, { [context.childInvalid]: !!validationMessages && !!highlightChildren });
  return cloneElement(children, {
    ...children.props,
    ...otherProps,
    className,
    invalid: !!validationMessages,
  });
}

export const FormGroup = (
  { label, isRequired, children, validationMessages, className, highlightChildren }
) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'formGroup');
  return (
    <div className={classnames(context.container, className)}>
      {!!label && (
        <label className={context.label}>
          <span className={classnames({ [context.labelRequired]: !!isRequired })}>{label}</span>
        </label>
      )}
      {!!children.map && children.map((child, index) => modifyChild(
        { children: child, validationMessages },
        { key: index },
      ))}
      {!children.map && modifyChild({ children, validationMessages, highlightChildren, context, })}
      {!!validationMessages && (
        <div className={context.validationMessage}>
          {validationMessages.join('; ')}
        </div>
      )}
    </div>
  );
}

FormGroup.defaultProps = {
  highlightChildren: true,
};
