// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import 'Styles/application.scss';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-notifications/lib/notifications.css';
import * as pages from 'Pages';
import { render } from 'react-dom';
import React, { createElement } from 'react';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import 'Styles/application';
import { components } from 'Common';
import { THEME } from 'Constants';

const { Theme } = components

document.addEventListener('DOMContentLoaded', () => {
  //notifications
  const flashContent = document.getElementById('flash-content');
  if (!!flashContent) {
    renderComponent(NotificationContainer, flashContent);
  }

  const flashContainer = document.getElementById('flash-root');
  if (!!flashContainer) {
    const messages = JSON.parse(flashContainer.getAttribute('data')).flash;

    messages.forEach(({ type, text }) => {
      let noticeType = type;
      if (type === 'notice') {
        noticeType = 'info';
      } else if (type === 'alert') {
        noticeType = 'error';
      }
      NotificationManager[noticeType]
        (text, '', text.length > 100 ? 10000 : 4000)
    }
    );
  }

  const container = document.getElementById('react-root');
  if (!container) {
    return;
  }
  const dataAttr = JSON.parse(container.getAttribute('data'));

  if (!!dataAttr && !!dataAttr.component_name) {
    renderComponent(pages[dataAttr.component_name], container, THEME);
  }
  //open / close sidebar
  const sidebarButton = document.getElementById('sidebar-button');
  const systemWrapper = document.getElementById('system');
  const systemContentWrapper = document.getElementById('main__system');

  sidebarButton.addEventListener('click', (e) => {
    e.stopPropagation();
    systemWrapper.classList.add('main--visible_sidebar');
  });

  systemContentWrapper.addEventListener('click', () => {
    if (systemWrapper.classList.contains('main--visible_sidebar')) {
      systemWrapper.classList.remove('main--visible_sidebar');
    }
  });

});

export function renderComponent(componentFn, container = document.getElementById('react-root'), theme) {
  const props = JSON.parse(container.getAttribute('data'));
  const content = createElement(componentFn, props);

  if (!!theme) {
    render((
      <Theme theme={theme}>{content}</Theme>
    ), container);
  } else {
    render(
      content,
      container,
    );
  }
}


//recaptcha

window.recaptchaOnSuccess = function () {
  document.getElementById("submit-btn").disabled = false;
};

window.recaptchaOnError = function () {
  document.getElementById("submit-btn").disabled = true;
}

const form = document.getElementById('auth_submit_form')

if (form) {
  form.addEventListener('submit', function (e) {
    var response = grecaptcha.getResponse();
    if (!response) {
      e.preventDefault();
    }
  })
}

