import { NotificationManager } from 'react-notifications';

function getFlashType(type) {
  if (type === 'notice') {
    return 'info';
  }
  if (type === 'alert') {
    return 'error';
  }
  return type;
}

export function notifyOnFailedResponse(status) {
  if (status === 200) {
    return;
  }
  if (status === 403) {
    NotificationManager.info('Forbidden action');
  } else if (status === 422) {
    NotificationManager.warning('Sorry, but request has not passed some security protection, please reload the page and try again.');
  } else {
    NotificationManager.error('Something went wrong. Please contact system administrator');
  }
};

export function notifyOnSuccesResponse({ type, text }) {
  NotificationManager[getFlashType(type)](text);
}
