import React, { forwardRef, useContext, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '../Button';
import { getComponentPropsFromTheme } from '../../../helpers/getComponentPropsFromTheme';
import { ThemeContext } from '../../../context';

const tableClassNames = ['tableClassName', 'theadClassName', 'tbodyClassName', 'thClassName', 'tdClassName', 'trClassName',];

function getClassName(context, props, key) {
  return props[key] !== undefined ? props[key] : context[key]
}

const SortButton = ({ column, sorting, onSortChange, ...props }) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'table');

  let content;
  function handleClick() {
    const direction = sorting.direction === 'desc' ? 'asc' : 'desc';
    onSortChange(column.accessor, direction);
  }

  const noDirectionButtonContent = props.noDirectionButtonContent || context.noDirectionButtonContent;
  const ascButtonContent = props.ascButtonContent || context.ascButtonContent;
  const descButtonContent = props.descButtonContent || context.descButtonContent;
  if (!column.sortable) {
    content = null;
  } else if (sorting.column !== column.accessor) {
    content = noDirectionButtonContent;
  } else {
    content = sorting.direction === 'asc' ? ascButtonContent : descButtonContent;
  }

  return (
    <Button className={context.sortBtnClassName} state={context.sortBtnState} onClick={handleClick}>
      {content}
    </Button>
  );

};

SortButton.propTypes = {
  column: PropTypes.shape({
    accessor: PropTypes.string,
    sortable: PropTypes.bool,
  }),
  sorting: PropTypes.shape({
    direction: PropTypes.oneOf(['asc', 'desc']),
    column: PropTypes.string,
  }),
  onSortChange: PropTypes.func,
  noDirectionButtonContent: PropTypes.any,
  ascButtonContent: PropTypes.any,
  descButtonContent: PropTypes.any,
};

const Header = ({ theadClassName, thClassName, ...props }) => {
  return (
    <thead className={theadClassName}>
      <tr>
        {props.config.map((column) => (
          <th key={column.accessor || column.title} className={thClassName}>
            {column.title}
            {!!column.sortable && (
              <SortButton {...props} column={column} />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}

Header.propTypes = {
  config: PropTypes.array,
  column: PropTypes.shape({
    title: PropTypes.string,
    accessor: PropTypes.string,
  }),
  theadClassName: PropTypes.string,
  thClassName: PropTypes.string,
};

const Cell = forwardRef((props, ref) => {
  const { column, item, className, children, tdClassName } = props;
  const { cellFn, accessor, style } = column;
  let content;

  if (!!children) {
    content = children;
  } else if (!!cellFn) {
    content = cellFn(props);
  } else {
    content = item[accessor];
  }

  return (
    <td className={classnames(tdClassName, className)} style={style} ref={ref}>
      {content}
    </td>
  );
});

Cell.propTypes = {
  column: PropTypes.shape({
    accessor: PropTypes.string,
    style: PropTypes.object,
    cellFn: PropTypes.func,
  }),
  item: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  tdClassName: PropTypes.string,
};

const Row = ({ item, config, onClick, className, rowClassFn, trClassName, tdClassName }) => {
  const rowAddonClass = rowClassFn ? rowClassFn(item) : null;
  const handleClick = onClick ? () => { onClick(this.props.item); } : undefined;

  return (
    <tr className={classnames(trClassName, className, rowAddonClass)}
      onClick={handleClick}>
      {config.map(colConfig => (
        <Cell item={item} column={colConfig}
          key={colConfig.accessor || colConfig.title}
          tdClassName={tdClassName} />
      ))}
    </tr>
  );
}

Row.propTypes = {
  item: PropTypes.object,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    accessor: PropTypes.string,
  })),
  onClick: PropTypes.func,
  className: PropTypes.string,
  rowClassFn: PropTypes.func,
  trClassName: PropTypes.string,
  tdClassName: PropTypes.string,
}

const Body = ({ tbodyClassName, data, ...otherProps }) => (
  <tbody className={tbodyClassName}>
    {data.map((item, index) => (
      <Row item={item} key={index} {...otherProps} />
    ))}
  </tbody>
);

export const Table = (props) => {
  const context = getComponentPropsFromTheme(useContext(ThemeContext), 'formGroup');

  const classNames = tableClassNames.reduce((res, key) => {
    return { ...res, [key]: getClassName(context, props, key) }
  }, {});

  return (
    <div>
      <table className={classNames.tableClassName}>
        <Header {...props} {...classNames} />
        <Body {...props} {...classNames} />
      </table>
    </div>
  );
}
