import React, { useState, Fragment, useEffect } from 'react';
import { helpers } from 'Common';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import classnames from 'classnames';
import { notifyOnFailedResponse, notifyOnSuccesResponse } from 'Helpers/notifications';

const { AJAX, moveInArray } = helpers;

export default function MembershipsOrderingForm({ fetch_url, move_url }) {
  const [state, setState] = useState({
    items: [],
    loading: true,
  });

  async function fetchData() {
    const res = await AJAX.get(fetch_url);

    setState({
      loading: false,
      items: res.data
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function onMove(args) {
    setState({
      ...state,
      loading: true
    })

    const res = await AJAX.post(`${args.item.move_api_url}?new_position=${args.indexTo + 1}`, {
      notifyOnFailedResponse,
      notifyOnSuccesResponse
    });

    if (res.flash_type !== 'success') {
      setState({
        ...state,
        loading: false
      });

      return
    }

    const reorderedItems = moveInArray(state.items, args.indexFrom, args.indexTo);

    setState({
      items: reorderedItems,
      loading: false
    });
  }

  return (
    <>
      <h2 className="tournaments-list-title">Tournament Memberships Order</h2>
      <div className="tournaments-list-wrapper">
        {state.loading && (
          <div className="tournaments-list-wrapper__backdrop"></div>
        )}
        <div className="tournaments-list">
          <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
            {state.items.map((item, index) => (
              <Fragment key={item.id}>
                <OrderControlDropTarget index={index} onMove={onMove} />
                <OrderControlItem item={item} index={index} />
                {index == (state.items.length - 1) && (
                  <OrderControlDropTarget index={state.items.length} onMove={onMove} />
                )}
              </Fragment>
            ))}
          </DndProvider>
        </div>
      </div>
    </>
  )
}


export const OrderControlDropTarget = (props) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'membership',
    drop: ({ item, index }) => {
      const indexTo = index > props.index ? props.index : props.index - 1;
      props.onMove({ item, indexFrom: index, indexTo })
    },
    canDrop: ({ index }) => {
      return ((index !== props.index) && (props.index - index) !== 1);
    },
    collect: mon => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop(),
      isDragging: !!mon.getItemType(),
    }),
  })

  return (
    <div ref={drop} className="tournaments-list__drop-target">
      <div className={classnames('tournaments-list__drop-area', {
        'tournaments-list__drop-area--hovered': !!canDrop && !!isOver
      })}></div>
    </div>
  )
}

export const OrderControlItem = ({ item, index, }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'membership',
    item: { type: 'membership', item, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div className="tournaments-list__item" ref={drag} style={{ cursor: 'move', opacity: isDragging ? 0.5 : 1 }}>
      <p>{item.name}</p>
    </div>
  );
}

