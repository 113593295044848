/**
 * Helper get array of items and value, which should this item has. By default keyName is "id"
 * @param {Array} src array of items
 * @param {*} key Value, which should be contained by item
 * @param {String} keyName Name of key, which should contain value
 */
export function findItemByKeyInArray(src, key, keyName = 'id') {
  const wantedItem = src.filter(item => item[keyName] === key)[0];
  return wantedItem || null;
}
