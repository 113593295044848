/**
 * This helper get array and item, and if item presence, helper removes it,
 * else adds it
 * @param {Array} src array of values
 * @param {*} item item, presence of which we want to toggle
 * @returns {Array} return new data array
 */
export function toggleItemPresenceInArray(src, item) {
  const index = src.indexOf(item);
  return index === -1 ? add(src, item) : remove(src, index);
}
