import React from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';
const { FormGroup } = components;
export class PackageGroupForm extends CommonForm {
  modelName = 'package_group';

  defaultItem = {
    name: '',
    color: ''
  }

  handleChangeName = ({ target }) => this.changeValue('name', target.value);
  handleChangeColor = ({ target }) => this.changeValue('color', target.value);

  get body() {
    const { item } = this.state;
    return (
      <div className="card card--size-md">
        <FormGroup label="Name" isRequired
          validationMessages={this.validationMessages.name}>
          <input type="text" className="input-group__input" value={item.name}
            onChange={this.handleChangeName} />
        </FormGroup>
        <FormGroup label="Color" isRequired
          validationMessages={this.validationMessages.color}>
          <input type="color" value={item.color} onChange={this.handleChangeColor} />
        </FormGroup>
        {this.formFooter}
      </div>
    );
  }
}
