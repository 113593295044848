import React from 'react';

const Preloader = ({ size = 7 }) => {
  return (
    <div className="preloader">
      <i className={`fas fa-cog fa-spin fa-${size}x`}></i>
    </div>
  );
};

export default Preloader;
